<template>
  <Background :background class="px-24 py-24 md:py-32" rounded="desktop">
    <div class="m-auto flex w-full flex-col items-center">
      <div class="w-full md:w-[544px]">
        <div class="flex flex-col items-start gap-4 text-left">
          <div v-if="title" class="heading-1">{{ title }}</div>
          <div v-if="text" class="body-1">{{ text }}</div>
        </div>

        <RevForm class="py-24" :initial-values :validate @submit="onSubmit">
          <template #default="{ values, errors }">
            <div class="flex flex-col">
              <div class="flex flex-col justify-center gap-8 md:flex-row">
                <RevInputText
                  id="email-collector"
                  v-model="values.email"
                  class="w-full"
                  :error="errors.email"
                  helper-text-class="mt-8 !text-static-default-hi"
                  :icon="IconMail"
                  :label="i18n(translations.inputLabel)"
                  type="email"
                />

                <CmsButtonBase
                  class="!h-fit min-w-full md:min-w-fit"
                  :disabled="submitButtonIsDisabled"
                  :loading="isLoading"
                  type="submit"
                  variant="primary"
                  width="adaptive"
                >
                  {{ buttonLabel }}
                </CmsButtonBase>
              </div>
            </div>
          </template>
        </RevForm>

        <div class="caption">
          <p>
            {{ i18n(translations.legalTextShort) }}
          </p>

          <RevLink class="my-8 block" target="_blank" :to="legalLink">
            {{ i18n(translations.legalLinkText) }}
          </RevLink>

          <RevButtonBase
            v-if="areTermsVisible"
            aria-controls="collapsibleText"
            :aria-expanded="!isCollapsed"
            class="flex w-full items-start justify-start text-left"
            :icon="isCollapsed ? IconChevronDown : IconChevronUp"
            variant="secondary"
            @click="toggleCollapsedState"
          >
            <div class="h-full shrink-0">
              <component
                :is="isCollapsed ? IconChevronDown : IconChevronUp"
                :aria-hidden="true"
              />
            </div>
            <div>
              <p class="leading-body-1">{{ emailCollectorTerms?.title }}</p>
              <p
                id="collapsibleText"
                :class="['block', { hidden: isCollapsed }]"
              >
                {{ emailCollectorTerms?.text }}
              </p>
            </div>
          </RevButtonBase>

          <RevLink v-if="pdf" class="mt-8 block" target="_blank" :to="pdf.url">
            <span> {{ pdf.label }} </span>
          </RevLink>
        </div>
      </div>
    </div>
  </Background>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevForm, makeValidate, required } from '@ds/components/Form'
import { RevInputText } from '@ds/components/InputText'
import { RevLink } from '@ds/components/Link'
import { IconChevronDown } from '@ds/icons/IconChevronDown'
import { IconChevronUp } from '@ds/icons/IconChevronUp'
import { IconMail } from '@ds/icons/IconMail'

import Background from '../../shared-components/Background/Background.vue'
import CmsButtonBase from '../../shared-components/CmsButtonBase/CmsButtonBase.vue'

import translations from './EmailCollector.translations'
import type { EmailCollectorProps } from './EmailCollector.types'
import { useCollectEmail } from './useCollectEmail'

const props = defineProps<EmailCollectorProps>()

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const initialValues = {
  email: '',
}

const i18n = useI18n()

const { collectEmail, isLoading, submitButtonIsDisabled } = useCollectEmail()

const currentLocale = useI18nLocale()

const legalLink = `${currentLocale}/legal/data-protection`

const isCollapsed = ref(true)

function toggleCollapsedState() {
  isCollapsed.value = !isCollapsed.value
}

const validate = makeValidate<typeof initialValues>({
  email: required(i18n(translations.formMandatoryError)),
})

const trackingData = computed(() => ({
  ...(props.tracking || {}),
  name: props.title,
}))

const areTermsVisible = computed(() => {
  const { emailCollectorTerms: terms } = props

  return !!terms && !!terms.title && !!terms.text && !terms.hide
})

function onSubmit(values: typeof initialValues) {
  collectEmail({
    email: <string>values.email,
    trackingData: trackingData.value,
  })
}
</script>
